import {
  GatsbyImage,
  getImage,
  ImageDataLike,
} from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { CheckIcon } from '@heroicons/react/outline'

interface Props {
  title: string;
  items?: {
    id: string;
    name: string;
    description: string;
    image?: {
      alt: string;
      gatsbyImageData: ImageDataLike;
    };
  }[];
}

const MenuCategory: FC<Props> = (props) => (
  <div>
    <div className="max-w-4xl mx-auto py-4 sm:px-6 sm:py-8">
      <div className="px-4 sm:px-0">
        <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
          {props.title}
        </h2>
      </div>
      <div className="mt-16">
        <h2 className="sr-only">
          {props.title}
        </h2>
        <div className="space-y-16 sm:space-y-24">
          <div className="mt-6 flow-root px-4 sm:mt-10 sm:px-0">
            <div className="-my-6 divide-y divide-gray-200 sm:-my-10">
              {props.items?.map((item) => (
                <div key={item.id} className="flex py-6 sm:py-10">
                  <div className="min-w-0 flex-1 lg:flex lg:flex-col">
                    <div className="lg:flex-1">
                      <div className="sm:flex">
                        <div>
                          <h4 className="font-medium text-gray-900">{item.name}</h4>
                          <div
                            className="rich-text mt-2 text-sm text-gray-500"
                            dangerouslySetInnerHTML={{ __html: item.description }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {item.image && (
                    <div className="ml-4 flex-shrink-0 sm:m-0 sm:mr-6 sm:order-first">
                      <GatsbyImage
                        alt={item.image.alt}
                        className="col-start-2 col-end-3 sm:col-start-1 sm:row-start-1 sm:row-span-2 w-20 h-20 rounded-lg object-center object-cover md:w-40 md:h-40"
                        image={getImage(item.image.gatsbyImageData)!}
                        objectFit="cover"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MenuCategory;
