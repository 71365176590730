import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Banner from '../components/banner';
import Footer from '../components/footer';
import MenuCategory from '../components/menu-category';
import Navigation from '../components/navigation';

const MenuPage = (props) => (
  <div>
    <Helmet title="The Divided Sky Bar and Restaurant" />
    <Navigation
      logoData={props.data.logo.gatsbyImageData}
    />
    <main className="lg:relative container my-8 mx-auto">
      <h1 className="py-20 sm:py-24 text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
        {props.data.menuPage.heading}
      </h1>
      {props.data.menuPage.categories.map((category) => (
        <MenuCategory
          key={category.id}
          {...category}
        />
      ))}
    </main>
    <Footer />
    {props.data.contentfulBanner.message && (
      <Banner data={props.data.contentfulBanner} />
    )}
  </div>
);

export const query = graphql`
  {
    logo: contentfulAsset(title: { eq: "Logo" }) {
      id
      title
      gatsbyImageData(width: 240)
    }
    contentfulBanner {
      id
      message
    }
    menuPage {
      heading
      categories {
        id
        title
        items {
          id
          image {
            id
            alt
            gatsbyImageData(width: 240)
          }
          name
          description
        }
      }
    }
  }
`;

export default MenuPage;
